import React from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import ScriptTag from "react-script-tag";
import Link from "./link";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import _ from "lodash";

const convertChildren = (children, index) =>
  _.map(children, (childNode) =>
    convertNodeToElement(childNode, index, _.noop())
  );

export default function htmlToReact(html) {
  if (!html) {
    return null;
  }
  const codeString = "(num) => num + 1";
  return ReactHtmlParser(html, {
    transform: (node, index) => {
      if (node.type === "tag" && node.name === "code") {
        const className = node.attribs?.class;
        if (className === "language-javascript") {
          return (
            <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
              {convertChildren(node.children, index)}
            </SyntaxHighlighter>
          );
        }
        if (className === "language-python") {
          return (
            <SyntaxHighlighter language="python" style={vscDarkPlus}>
              {convertChildren(node.children, index)}
            </SyntaxHighlighter>
          );
        }
      }
      if (node.type === "script") {
        if (!_.isEmpty(node.children)) {
          return (
            <ScriptTag key={index} {...node.attribs}>
              {convertChildren(node.children, index)}
            </ScriptTag>
          );
        } else {
          return <ScriptTag key={index} {...node.attribs} />;
        }
      } else if (node.type === "tag" && node.name === "a") {
        const href = node.attribs.href;
        const props = _.omit(node.attribs, "href");
        // use Link only if there are no custom attributes like style, class, and what's not that might break react
        if (_.isEmpty(props)) {
          return (
            <Link key={index} to={href} {...props}>
              {convertChildren(node.children, index)}
            </Link>
          );
        }
      }
    },
  });
}
