import React from "react";
import _ from "lodash";

import { Link, withPrefix, classNames } from "../utils";

export default class Header extends React.Component {
  componentDidMount() {
    if (window.innerWidth < 1024) {
      return;
    }
    setTimeout(() => {
      const currentTarget = document.querySelector(
        ".menu-item.current-menu-item"
      );
      if (!currentTarget) {
        return;
      }
      currentTarget.style.fontSize = "16px";

      setTimeout(() => {
        const currentTarget = document.querySelector(
          ".menu-item.current-menu-item"
        );
        if (!currentTarget) {
          return;
        }
        currentTarget.style.fontSize = "14px";
      }, 140);
    }, 0);

    const overElement = document.getElementById("mouseover-effect");
    const overElementInner = document.getElementById("mouseover-effect-inner");
    // This function will be called every time the mouse moves
    function handleMouseMove(event) {
      var x = event.clientX; // Get the horizontal coordinate
      var y = event.clientY; // Get the vertical coordinate

      //console.log("Mouse position: X=" + x + ", Y=" + y);

      overElement.classList.add("active");
      overElement.style.top = y - 24 + "px";
      overElement.style.left = x - 24 + "px";

      overElementInner.classList.add("active");
      overElementInner.style.top = y - 6 + "px";
      overElementInner.style.left = x - 6 + "px";
    }
    function handleMouseLeave() {
      overElement.classList.remove("active");
      overElementInner.classList.remove("active");
    }
    function handleMouseClick() {
      overElementInner.style.height = "14px";
      overElementInner.style.width = "14px";
      setTimeout(() => {
        overElementInner.style.height = "12px";
        overElementInner.style.width = "12px";
      }, 100);
    }

    function debounce(func, wait) {
      let timeout;

      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }

    const header = document.querySelector(".site-header .site-header-wrap");
    // Attach the event listener to the whole document
    header &&
      header.addEventListener("mousemove", debounce(handleMouseMove, 40));
    header && header.addEventListener("mouseleave", handleMouseLeave);
    header && header.addEventListener("click", handleMouseClick); 
  }

  render() {
    return (
      <header
        id="masthead"
        className={
          "site-header " +
          _.get(
            this.props,
            "pageContext.site.siteMetadata.header.background",
            null
          )
        }
      >
        <div className="site-header-wrap">
          <div className="site-header-inside">
            <div id="mouseover-effect"></div>
            <div id="mouseover-effect-inner"></div>
            <div className="site-branding">
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.header.profile_img",
                null
              ) && (
                <p className="profile">
                  <Link to={withPrefix("/")}>
                    <img
                      src={withPrefix(
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.profile_img",
                          null
                        )
                      )}
                      className="avatar"
                      alt="Author Avatar"
                    />
                  </Link>
                </p>
              )}
              <p></p>
              <div className="site-identity">
                <p className="site-title">
                  <Link to={withPrefix("/")}>
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.title",
                      null
                    )}
                  </Link>
                </p>
                {_.get(
                  this.props,
                  "pageContext.site.siteMetadata.header.tagline",
                  null
                ) && (
                  <p className="site-description">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.tagline",
                      null
                    )}
                  </p>
                )}
              </div>
              {(_.get(
                this.props,
                "pageContext.site.siteMetadata.header.has_nav",
                null
              ) ||
                _.get(
                  this.props,
                  "pageContext.site.siteMetadata.header.has_social",
                  null
                )) && (
                <button id="menu-toggle" className="menu-toggle">
                  <span className="screen-reader-text">Menu</span>
                  <span className="icon-menu" aria-hidden="true" />
                </button>
              )}
            </div>
            {(_.get(
              this.props,
              "pageContext.site.siteMetadata.header.has_nav",
              null
            ) ||
              _.get(
                this.props,
                "pageContext.site.siteMetadata.header.has_social",
                null
              )) && (
              <nav
                id="main-navigation"
                className="site-navigation"
                aria-label="Main Navigation"
              >
                <div className="site-nav-wrap">
                  <div className="site-nav-inside">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.has_nav",
                      null
                    ) && (
                      <ul className="menu">
                        {_.map(
                          _.get(
                            this.props,
                            "pageContext.site.siteMetadata.header.nav_links",
                            null
                          ),
                          (action, action_idx) => {
                            let pageUrl = _.trim(
                              _.get(this.props, "pageContext.url", null),
                              "/"
                            );
                            let actionUrl = _.trim(
                              _.get(action, "url", null),
                              "/"
                            );
                            return (
                              <li
                                key={action_idx}
                                className={classNames("menu-item", {
                                  "current-menu-item": pageUrl === actionUrl,
                                  "menu-button":
                                    _.get(action, "style", null) === "button",
                                  "projects-list":
                                    _.get(action, "label", null) === "Projects",
                                })}
                                onClick={(el) => {
                                  el.currentTarget.querySelector("a").click();
                                }}
                              >
                                <Link
                                  className={classNames({
                                    button:
                                      _.get(action, "style", null) === "button",
                                  })}
                                  to={withPrefix(_.get(action, "url", null))}
                                  {...(_.get(action, "new_window", null)
                                    ? { target: "_blank", rel: "noopener" }
                                    : null)}
                                >
                                  {_.get(action, "label", null)}
                                </Link>
                                {_.get(action, "label", null) ===
                                  "Projects" && (
                                  <div id="projects-list-group">
                                    <div>
                                      <a href="#project--uride">1. u-ride</a>
                                      <a href="#project--friends-day">
                                        2. vodafone
                                      </a>
                                      <a href="#project--christmas-campaign">
                                        3. vodafone
                                      </a>
                                      <a href="#project--vodafone-backoffice">
                                        4. vodafone
                                      </a>
                                      <a href="#project--home-insurance">
                                        5. ok-seguros
                                      </a>
                                      <a href="#project--auto-insurance">
                                        6. ok-seguros
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.has_social",
                      null
                    ) && (
                      <div className="social-links">
                        {_.map(
                          _.get(
                            this.props,
                            "pageContext.site.siteMetadata.header.social_links",
                            null
                          ),
                          (action, action_idx) =>
                            action && (
                              <Link
                                key={action_idx}
                                className={classNames({
                                  "button button-icon":
                                    _.get(action, "style", null) === "icon",
                                })}
                                to={withPrefix(_.get(action, "url", null))}
                                {...(_.get(action, "new_window", null)
                                  ? { target: "_blank", rel: "noopener" }
                                  : null)}
                              >
                                {_.get(action, "style", null) === "icon" &&
                                _.get(action, "icon_class", null) ? (
                                  <React.Fragment>
                                    <span
                                      className={
                                        "fab " +
                                        _.get(action, "icon_class", null)
                                      }
                                      aria-hidden="true"
                                    />
                                    <span className="screen-reader-text">
                                      {_.get(action, "label", null)}
                                    </span>
                                  </React.Fragment>
                                ) : (
                                  _.get(action, "label", null)
                                )}
                              </Link>
                            )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </nav>
            )}
          </div>
        </div>
      </header>
    );
  }
}
